import type {CDSNotificationStatus} from "@ciscodesignsystems/cds-react-notification";

import type {BannerItem, BannerComponent} from "types/bannerState";

// Constants for TDR event type to status mapping
const EVENT_TYPE_TO_STATUS_MAP = new Map<string, CDSNotificationStatus>([
  ["major", "negative"],
  ["minor", "warning"],
  ["performance", "warning"],
  ["maintenance", "info"],
]);

// Constants for TDR region to XDR region mapping
const TDR_REGION_TO_XDR_REGION_MAP = new Map([
  ["Australia", "PROD_APJC"],
  ["Asia", "PROD_APJC"],
  ["India", "PROD_APJC"],
  ["Europe", "PROD_EU"],
  ["North America", "PROD_US"],
]);

// Maps event type to CDS notification status
export const mapEventTypeToStatus = (
  eventType: string = "maintenance",
): CDSNotificationStatus => {
  return EVENT_TYPE_TO_STATUS_MAP.get(eventType) ?? "info";
};

// Maps TDR region to XDR region
const mapTDRRegionToXdrRegion = (tdrRegion: string): string | undefined => {
  return TDR_REGION_TO_XDR_REGION_MAP.get(tdrRegion);
};

// Checks if a component has a specific region
const componentHasRegion =
  (region: string | undefined) =>
  (component: BannerComponent): boolean => {
    return mapTDRRegionToXdrRegion(component.region.name) === region;
  };

// Filters items by region
// Filters only by regions listed in TDR_REGION_TO_XDR_REGION_MAP, else all banners will show up for TEST / INT etc.
export const filterItemsByRegion =
  (region: string | undefined) =>
  (items: BannerItem[] = []): BannerItem[] => {
    const hasFilterableRegion = items?.some?.((item: BannerItem) =>
      item?.components?.some(componentHasRegion(region)),
    );
    return hasFilterableRegion
      ? items.filter((item: BannerItem) =>
          item?.components?.some(componentHasRegion(region)),
        )
      : items;
  };
